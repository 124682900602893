import React, { useEffect } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { FiSettings } from "react-icons/fi";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import PrivateRoute from "./routing/PrivateRoute";
import { Navbar, Footer, Sidebar, ThemeSettings } from "./components";
import {
  //Home,
  Profile,
  Approved,
  Rejected,
  Viewloan,
  UpdateClass,
  Compliance,
  SignIn,
  SignUp,
  Welcome,
  Customers,
  ViewCustomer,
  PendingLoans,
  Institutions,
  ViewInstitution,
} from "./pages";
import "./App.css";
import { useStateContext } from "./contexts/ContextProvider";
import ViewMandate from "./pages/ViewMandate";
import Mandates from "./pages/Mandates";

const App = () => {
  const {
    user,
    setCurrentColor,
    setCurrentMode,
    currentMode,
    activeMenu,
    currentColor,
    themeSettings,
    setThemeSettings,
  } = useStateContext();

  useEffect(() => {
    const currentThemeColor = localStorage.getItem("colorMode");
    const currentThemeMode = localStorage.getItem("themeMode");
    if (currentThemeColor && currentThemeMode) {
      setCurrentColor(currentThemeColor);
      setCurrentMode(currentThemeMode);
    }
  }, [setCurrentColor, setCurrentMode]);

  //console.log(user + " from context");
  return (
    <div className={currentMode === "Dark" ? "dark" : ""}>
      <BrowserRouter>
        <Switch>
          {/* auth  */}
          <Route path="/signin" component={SignIn} />
          <Route path="/signup" component={SignUp} />
          <Route path="/welcome/school" component={Welcome} />
          {/* end switch  */}
          <div className="flex relative dark:bg-main-dark-bg">
            <div className="fixed right-4 bottom-4" style={{ zIndex: "1000" }}>
              <TooltipComponent content="Settings" position="Top">
                <button
                  type="button"
                  onClick={() => setThemeSettings(true)}
                  style={{ background: currentColor, borderRadius: "50%" }}
                  className="text-3xl text-white p-3 hover:drop-shadow-xl hover:bg-light-gray"
                >
                  <FiSettings />
                </button>
              </TooltipComponent>
            </div>
            {activeMenu ? (
              <div className="w-72 fixed sidebar dark:bg-secondary-dark-bg bg-white ">
                <Sidebar />
              </div>
            ) : (
              <div className="w-0 dark:bg-secondary-dark-bg">
                <Sidebar />
              </div>
            )}
            <div
              className={
                activeMenu
                  ? "dark:bg-main-dark-bg bg-main-bg min-h-screen md:ml-72 w-full"
                  : "bg-main-bg dark:bg-main-dark-bg w-full min-h-screen flex-2"
              }
            >
              <div className="fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full">
                <Navbar user={user} />
              </div>
              <div>
                {themeSettings && <ThemeSettings />}
                {/* dashboard  */}
                <PrivateRoute path="/" exact component={Customers} />
                <PrivateRoute exact path="/customers" component={Customers} />
                <PrivateRoute exact path="/customers/view-customer/:id" component={ViewCustomer} />
                <PrivateRoute exact path="/institutions" component={Institutions} />
                <PrivateRoute exact path="/institutions/view-customer/:id" component={ViewInstitution} />
                <PrivateRoute path="/pending" component={PendingLoans} />

                {/* pages  */}
                <PrivateRoute path="/profile" component={Profile} />
                <PrivateRoute path="/approved" component={Approved} />
                <PrivateRoute path="/rejected" component={Rejected} />
                <PrivateRoute path="/view-loan/:id" component={Viewloan} />
                <PrivateRoute
                  path="/update-class/:id"
                  component={UpdateClass}
                />
                <PrivateRoute
                  path="/compliance/personal"
                  component={Compliance}
                />
                <PrivateRoute path="/mandates" component={Mandates} />
                <PrivateRoute
                  path="/view-mandate/:mandateRef/:customerId/:authorisationCode"
                  component={ViewMandate}
                />
              </div>
              <Footer />
            </div>
          </div>
        </Switch>
      </BrowserRouter>
    </div>
  );
};

export default App;
